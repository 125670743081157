import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useGetBoundingClientRect } from '/machinery/useGetBoundingClientRect'
import { routeMap } from '/routeMap'

import { MediaWindow } from '/features/pageOnly/MediaWindow'
import { Tag } from '/features/buildingBlocks/Tag'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { ButtonLinkBlueSm } from '/features/buildingBlocks/Button'
import { TagsWrapper } from '/features/buildingBlocks/TagsWrapper'

import styles from './JobAreaSliderDesktop.css'

export function JobAreaSliderDesktop({ jobAreaCards, activeSlide, handleSlideChange }) {
  const amountOfCards = jobAreaCards?.length + 1
  const { ref } = useScrollProgressionProps({ amountOfCards, handleSlideChange, activeSlide })
  const { y, height, elementRef } = useGetBoundingClientRect()

  return (
    <section className={styles.component}>
      <ContainerXl>
        <div ref={elementRef} className={styles.jobAreaSlider}>
          <JobAreaSliderNav layoutClassName={styles.jobAreaNavLayout} {...{ jobAreaCards, activeSlide, y, height }} />
          <div className={styles.images}>
            {jobAreaCards.map((area, index) => (
              <Slide key={index} layoutClassName={styles.slideLayout} {...{ area, handleSlideChange, activeSlide, index }} />
            ))}
          </div>
          <div className={styles.contentContainer} style={{ '--container-height': 100 * amountOfCards + 'vh' }} {...{ ref }}>
            <JobAreaSlideContent layoutClassName={styles.slideContentLayout} {...{ jobAreaCards, activeSlide }} />
          </div>
        </div>
      </ContainerXl>
    </section>
  )
}

function Slide({ area, layoutClassName }) {
  const { image } = area

  return (
    <section data-x='jobarea-slide' className={cx(styles.componentSlide, layoutClassName)}>
      <div className={styles.windowImage}>
        <MediaWindow aspectRatio={0.7 / 1} distanceOverride={70} {...{ image }} />x
      </div>
    </section>
  )
}

function JobAreaSlideContent({ jobAreaCards, activeSlide, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentJobAreaSlideContent, layoutClassName)}>
      {jobAreaCards && jobAreaCards.map((card, id) => (
        <JobAreaSlide key={id} layoutClassName={styles.jobAreaSlideLayout} {...{ card, id, activeSlide }} />
      ))}
    </div>
  )
}

function JobAreaSlide({ card, id, activeSlide, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const title = card.title || card.jobAreaReference?.title
  const active = id === activeSlide

  return (
    <div key={id} className={cx(styles.componentJobAreaSlide, layoutClassName, active && styles.active)}>
      <div className={cx(styles.headingReveal, active && styles.active)}>
        <h2 className={styles.jobAreaHeading}>{title}</h2>
      </div>

      {card.tags?.length && (
        <div className={cx(styles.jobAreaSlideContentTags, active && styles.active)}>
          <TagsWrapper>
            {card.tags.map((tag, i) => (
              <Tag key={i} label={tag} colorScheme='default' />
            ))}
          </TagsWrapper>
        </div>
      )}

      <div className={cx(styles.slideTextAndButton, active && styles.active)}>
        <p className={styles.jobAreaContentText}>{card.description}</p>
        <ButtonLinkBlueSm
          label={__`read-more`}
          dataX='goto-jobarea'
          href={routeMap.app.jobArea({ language, slug: card.jobAreaReference?.slug.current })}
          layoutClassName={styles.readmoreButtonLayout}
        />
      </div>
    </div>
  )
}

function JobAreaSliderNav({ jobAreaCards, activeSlide, y, height, layoutClassName }) {
  const amountOfCards = jobAreaCards?.length

  return (
    <div className={cx(styles.componentJobAreaSliderNav, layoutClassName)}>
      {jobAreaCards && jobAreaCards.map((_, i) => (
        <JobAreaSliderButton key={i} id={i} isActive={i === activeSlide} layoutClassName={styles.buttonLayout} {...{ y, height, amountOfCards }} />
      ))}
    </div>
  )
}

function JobAreaSliderButton({ id, isActive, y, height, amountOfCards, layoutClassName }) {
  return <button onClick={handleClick} data-x='go-to-slide' aria-label='slide button' className={cx(styles.componentJobAreaSliderButton, isActive && styles.isActive, layoutClassName)} />


  function handleClick() {
    const offset = id * height / amountOfCards
    const top = y + offset

    window.scrollTo({ top, behavior: 'smooth' })
  }
}

function useScrollProgressionProps({ amountOfCards, handleSlideChange, activeSlide }) {
  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.center() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange(x) {
      const offset = activeSlide >= amountOfCards - 2 ? 0 : 0.2
      const slide = Math.round(x * (amountOfCards - 1) - offset)
      handleSlideChange(slide)
    }
  })

  return { ref }
}
