import { useMediaQuery } from '@kaliber/use-media-query'

import { JobAreaSliderDesktop } from '/features/pageOnly/JobAreaSliderDesktop'
import { JobAreaSliderMobile } from '/features/pageOnly/JobAreaSliderMobile'

import mediaStyles from '/cssGlobal/media.css'

export function JobAreaSlider({ jobAreaCards, layoutClassName = undefined }) {
  const [activeSlide, setActiveSlide] = React.useState(0)
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  return (
    isViewportMd
      ? <JobAreaSliderDesktop {...{ jobAreaCards, activeSlide, handleSlideChange }} />
      : <JobAreaSliderMobile {...{ jobAreaCards, activeSlide, handleSlideChange, layoutClassName }} />
  )

  function handleSlideChange(slide) {
    setActiveSlide(slide)
  }
}
