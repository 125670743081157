import { animated, useTransition } from '@react-spring/web'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { ImageCover } from '/features/buildingBlocks/Image'
import { ButtonLinkBlueSm } from '/features/buildingBlocks/Button'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './JobAreaSliderMobile.css'

export function JobAreaSliderMobile({ jobAreaCards, activeSlide, handleSlideChange, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const slidesNum = jobAreaCards?.length ?? 0
  const nextSlide = (activeSlide + 1) % slidesNum
  const prevSlide = (activeSlide - 1 + slidesNum) % slidesNum

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum,
    slidesPerView: 1,
    spacing: isViewportMd ? -40 : -50,
    initialSlide: 0,
    sliderDisabled: false,
    origin: 'center',
    loop: true,
    interactionTitle: 'job-area-slider',
    additionalOptions: {
      defaultAnimation: {
        duration: 1000,
      }
    }
  })

  return (
    <section data-x='job-area-slider' className={cx(styles.component, layoutClassName)}>
      <div ref={sliderRef} className={cx('keen-slider', styles.jobAreaSlider)}>
        {jobAreaCards && jobAreaCards.map((card, i) =>
          <JobAreaSlide
            key={i}
            isNextSlide={i === nextSlide}
            isPreviousSlide={i === prevSlide}
            layoutClassName={styles.jobAreaSlideLayout}
            {...{ card }}
          />
        )}
      </div>

      <JobAreaSliderMobileNav {...{ jobAreaCards, activeSlide, handleCarouselSlide }} layoutClassName={styles.jobAreaNavLayout} />

      <JobAreaSlideContent layoutClassName={styles.jobAreaSlideContentLayout} {...{ jobAreaCards, activeSlide }}  />
    </section>
  )

  function handleCarouselSlide(slide) { instanceRef?.current?.moveToIdx(slide) }
}

function JobAreaSlide({ card, isNextSlide, isPreviousSlide, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentJobAreaSlide, 'keen-slider__slide', layoutClassName)}>
      <div className={cx(styles.jobAreaSlideImage, isNextSlide && styles.nextSlide, isPreviousSlide && styles.prevSlide)}>
        <div className={styles.jobAreaWindowContainer}>
          {card.image && <ImageCover image={card.image} aspectRatio={4 / 3} layoutClassName={styles.windowLayout} />}
        </div>
      </div>
    </div>
  )
}

function JobAreaSlideContent({ jobAreaCards, activeSlide, layoutClassName = undefined }) {
  const language = useLanguage()
  const { __ } = useTranslate()
  const isViewportLg = useMediaQuery(mediaStyles.viewportMd) ?? false
  const offset = isViewportLg ? 20 : 0
  const card = jobAreaCards[activeSlide]

  const transitions = useTransition(card, {
    key: activeSlide,
    from: { opacity: 0, y: offset },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -offset },
    config: { duration: 150 },
    exitBeforeEnter: true,
  })

  return transitions((style, card) => (
    <animated.div className={cx(styles.componentJobAreaSlideContent, layoutClassName)} {...{ style }}>
      <div className={styles.headingReveal}>
        <h3 className={styles.jobAreaHeading}>{card.title || card.jobAreaReference?.title}</h3>
      </div>

      <div className={styles.slideTextAndButton}>
        <p className={styles.jobAreaContentText}>{card.description}</p>
        <ButtonLinkBlueSm
          label={__`read-more`}
          dataX='go-to-jobarea'
          href={routeMap.app.jobArea({ language, slug: card.jobAreaReference?.slug.current })}
          layoutClassName={styles.readmoreButtonLayout}
        />
      </div>
    </animated.div>
  ))
}

function JobAreaSliderMobileNav({ jobAreaCards, activeSlide, handleCarouselSlide, layoutClassName }) {
  return (
    <div className={cx(styles.componentNav, layoutClassName)}>
      {jobAreaCards && jobAreaCards.map((_, xid) =>
        <JobAreaSliderMobileButton key={xid} id={xid} isActive={xid === activeSlide} {...{ handleCarouselSlide }} layoutClassName={styles.buttonLayout} />
      )}
    </div>
  )
}

function JobAreaSliderMobileButton({ id, isActive, handleCarouselSlide, layoutClassName }) {
  return (
    <button
      aria-label='slide button'
      data-x='go-to-slide'
      onClick={() => handleCarouselSlide(id)}
      className={cx(styles.componentButton, isActive && styles.jobAreaSliderActiveButton, layoutClassName)}
    />
  )
}
